import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { StaticImage } from "gatsby-plugin-image";
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import Obfuscate from 'react-obfuscate';
export const _frontmatter = {
  "title": "Huey Mobile Support"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout className="page huey-mobile-support-page" mdxType="Layout">
      <Seo title={props.pageContext.frontmatter.title} mdxType="Seo" />
      <div className="col-10 offset-1">
        <h1>{`Huey Mobile Support`}</h1>
        <br />
        <p className="text-center"><a href="/products/huey-h2o/support">Also see Huey H₂O Water Sensor support</a></p>
        <p className="text-center mt-5 font-italic fs-3">For any support queries,<br />send an email</p>
        <div className="container text-center">
          <Obfuscate className="btn btn-success btn-lg mx-auto d-inline" email="contact@huey.co" headers={{
            //         cc: 'dade@zero-cool.af',
            //         bcc: 'smith@machina.net',
            subject: 'Hello Huey',
            body: ''
          }} mdxType="Obfuscate" />
        </div>
      </div>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      